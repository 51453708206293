<template>
    <div id="app" style="padding:0 20px">
        <h1>光恋公会协议</h1>
        <p class="title">【提示条款】</p>
        <p>欢迎您与光恋平台（以下简称平台）共同签署《光恋公会协议》并使用本平台公会服务！您理解和同意本协议系根据《用户协议》等协议制定，本协议为前述协议不可分割的一部分，本协议未规定的，使用《用户协议》等协议中的相关约定。
        </p>
        <p class="title">【审慎阅读】</p>
        <p>您使用本平台公会服务前应当阅读并充分理解遵守本协议，本协议中与您的权益（可能）存在重大关系的条款（包括免除其他方责任的条款，限制权利的条款，约定的争议解决方式及司法管辖条款等），
            您使用本平台公会服务的行为将被视为已经仔细阅读，充分理解并毫无保留的接受本协议所有条款。</p>
        <p class="title">【特别提示】</p>
        <p>1. 您须确认自己已年满18周岁且具有完全民事行为能力。未成年用户或非完全民事行为能力用户使用本平台公会服务，必须得到家长或其他合法监护人的同意。本平台谨此提醒家长合理引导未成年子女的上网及消费行为，切实履行监护义务。
        </p>
        <p>2. 本协议将涉及<a href="#guild">《公会入驻合作协议》</a> <a href="#players">《玩家入会协议》</a>，您可点击以上协议标题快速前往相应协议阅读并理解。</p>
        <h1 id="guild">公会入驻合作协议</h1>
        <p> 光恋平台及未来不定期增加的站点或移动终端客户端，以下统称“平台”,公会系依法成立的法人，以下统称“公会”，旗下拥有多名签约主播，具有良好的主播发展管道和管理运营经验。</p>
        <p>双方就主播合作达成如下协议。</p>
        <p class="title"> 一、合作内容和形式</p>
        <p>1.平台对光恋享有合法权益；在双方合作期内，公会安排其旗下签约主播（以下统称“公会主播”或“主播”）在光恋（以下统称“平台”或“光恋”）上以直播形式为用户提供演艺直播服务（以下统称“直播服务”）。</p>
        <p>2.公会主播由公会自行管理独立制约，但须遵守平台所制定的平台守则。光恋守则将不定期在平台处予以公示，或以其他方式提供给公会。</p>
        <p>3.平台提供相关的管理后台供公会及公会安排的主播使用。</p>
        <p>4.公会主播仅在光恋提供演艺直播服务，公会主播不受平台公司管理规章制度约束，不得视为平台与主播建立了劳动关系。</p>
        <p>平台无义务支付主播的任何费用，包括但不限于任何劳务费、服务费、社会保险、福利和医疗保险。</p>
        <p>公会有义务自行独立承担其与公会主播履行本协议项下合作所产生的费用并负责向公会主播、公会雇员及公会所雇第三方工作人员（如涉及）支付相应的一切工资、报酬、保险等。</p>
        <p>对于公会与公会主播、公会雇员和/或公会所雇第三方工作人员之间出现任何纠纷，包括但不限于拖欠工资、酬劳、保险及福利等情况，平台不承担任何责任。</p>
        <p>5.公会安排的主播名单以光恋数据为准。</p>
        <p>所有公会安排的主播均须入驻光恋，在入驻时同意《光恋用户服务协议》。</p>
        <p>6.公会指定专有联系人与平台沟通，按照本协议约定，发送及确认各类信息（包括但不限于入驻主播名单、被处罚主播名单），并及时解决合作中出现的各类问题。</p>
        <p> 如公会指定联系人出现变更的，公会联系人应立即通知平台。 否则，因此造成的损失由公会自行承担。</p>
        <p class="title"> 二、平台的权利和义务</p>
        <p>1.光恋有权基于自身平台实际发布及不定时更新相关平台规范，包括但不限于《光恋用户服务协议》、《光恋公会协议》。</p>
        <p>2.平台有权对公会主播的直播情况进行监督，对于不符合平台要求或平台认为其形象与平台不符的主播，平台有权要求公会予以指导调整，对此，公会应确保公会主播全力配合以满足平台要求。</p>
        <p>3.平台有权对公会主播提供的直播内容进行审查，对于不符合相关法律法规、相关主管部门要求和平台规范或平台认为有可能损害平台利益的直播内容的，平台有权拒绝播放，并要求重新提供符合平台要求的直播内容，或直接取消其主播资格，并同时扣减相应公会费用。
        </p>
        <p>同时，平台的审查为形式审查，并不因此而减免或转移公会的任何责任和义务。</p>
        <p>4.平台将竭力保证平台的稳定运营，保证主播在平台的顺利直播。</p>
        <p>5.平台将竭力提供完善的后台，使公会和公会推荐的主播可以实时看到主播的直播累计时间等数据。</p>
        <p class="title"> 三、公会的权利和义务</p>
        <p>1.公会须遵守光恋发布及不定时更新的平台规范，包括但不限于《光恋公会协议》。</p>
        <p>2.公会须合理安排公会主播的直播活动，公会须对公会主播的直播内容质量全权负责，到达平台的审核标准。</p>
        <p>公会应确保公会主播的直播内容符合国家相关法律法规、相关主管部门要求和平台规范，且不侵犯任何第三方的包括但不限于知识产权、人身权及财产权等任何合法权益。</p>
        <p>3.公会认可，光恋已经发布或将来可能公开发布或发送给平台的各类规范、公告或通知构成光恋的管理规范，公会应予以全面遵守。</p>
        <p>4.公会安排的主播通过光恋的申请入驻流程后，正式成为在光恋入驻的主播。</p>
        <p>5.公会负责主播的招聘、安排、培训和日常管理。 </p>
        <p>公会须提供公会主播直播所需的设备及维护服务，并需要对公会主播个人进行相关包装来提供直播服务质量。</p>
        <p>6.公会有义务监督管理公会主播的行为，并对主播在直播过程中发生的涉及色情、暴力引导、迷信传播、反动言论、影响中国大陆稳定团结等行为进行及时的制止。</p>
        <p>若平台发现有如上任一行为，平台有权即刻取消该主播的主播权，且无须承担任何责任。</p>
        <p>7.公会有权根据自身制定的管理制度处罚所管辖主播，如取消某主播资格的，须书面通知平台。</p>
        <p>8.公会保证公会及公会主播不得利用非法软件或其他作弊方式进行欺诈行为（包括但不限于刷虚拟礼物、刷虚拟币、刷打赏、刷票数、刷用户数等），也不得利用平台系统漏洞来获得不正当的非法利益。</p>
        <p>如公会及公会主播违反上述约定，平台有权采取警告、扣减费用、没收额外奖励、封禁等方式予以惩戒，情节严重的，平台有权解除合同，且不承担任何违约责任。</p>
        <p>9.公会应确保公会主播文明直播，直播过程中不得与用户发生冲突、争议，否则，公会应确保公会主播按平台要求处理冲突、争议（包括但不限于道歉等），同时，平台有权采取警告、扣减费用、没收额外奖励、封禁等方式予以惩戒，情节严重的，平台有权解除合同，且不承担任何违约责任。
        </p>
        <p>10.公会保证其在签订本协议之前，未签订任何和本合同相抵触的或影响本合同履行的合同、声明或其他任何有约束力的文件。</p>
        <p>11.鉴于平台重视合作方对合作方旗下主播合法权益的保护。 公会须按照与公会主播的约定按时足额支付其费用。
            若公会违反此约定，平台有权视情况严重程度解除本合同，同时，双方均认可此行为会严重影响平台声誉，给平台或光恋造成损失。</p>
        <p>12.公会须按照平台要求，定期向平台提交书面报告，汇报公会主播的直播情况。</p>
        <p>13.公会承诺愿意配合光恋不定期举办的各项活动。</p>
        <p>平台如有线下宣传或其他合作活动，公会须提供合适的主播人选，并配合平台相关的活动或宣传</p>
        <p>14.公会保证，其具备签署和履行本合同的资格和能力，其履行本协议符合国家相关法律规定，且不侵犯任何第三方合法权益，并且不违反任何对其有约束力的法律文件或合同及合同等的限制。</p>
        <p>公会保证，公会主播均具备完全民事权利能力和完全民事行为能力以及其他履行本合同所应具备的合法资格和能力。</p>
        <p>15.平台将视情况向公会推送主播资源，对于平台所推送的主播资源，公会应在接到推送后【3】个工作日内确认是否接收，如公会确认接收，则公会将按本合同约定统一负责对平台所推送的主播资源进行管理。
            但公会须保证，平台所推送的主播资源应为平台平台的独家主播资源。
            未经平台书面同意，若上述主播直接或间接、有偿或无偿地在任何其他平台进行在线演艺活动或从事类似业务（上述“其他平台”指在线视频，语音娱乐平台，包括但不限于抖音、快手、微视、YY、火山、六间房、花椒、映客、一直播、熊猫TV、斗鱼、美拍等；“类似业务”包括但不限于通过在线演艺活动，收取虚拟礼物获得收益的业务，无论该收益是平台方直接支付或是网络用户直接支付），则视为公会违约。
        </p>
        <p>平台有权立即解除本协议，没收公会保证金、停止支付公会服务费用，并且公会应按照下述两种情况中较高者支付违约金）当月应得服务费款项及额外奖励之和的【2】倍金额；）合作期间平均每月服务费款项及额外奖励之和【2】倍金额。
        </p>
        <p>16.公会有权按照本协议规定时间和方式从平台获得相应的报酬。</p>
        <p class="title">四、保密条款</p>
        <p>公会有义务对本协议所涉及的全部内容，及在执行本协议过程中获悉的属于平台的且无法自公开管道获悉的文件、数据及讯息（包括商业信息、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密等）予以严格保密，未经平台允许不得向任何第三方披露。
        </p>
        <p>公会违反上述保密义务给平台造成损失的，平台有权向公会追偿。</p>
        <p class="title">五、违约责任</p>
        <p>1.公会若违反本协议第三条任意一款约定的，平台有权决定同时采取以下单项或多项措施</p>
        <p style="text-indent:46px">a）书面警告要求公会改正；</p>
        <p style="text-indent:46px">b）要求公会支付违约金，金额为下述两种情况中较高者</p>
        <p style="text-indent:56px">i）当月应得服务费款项及额外奖励之和；</p>
        <p style="text-indent:56px">ii）合作期间平均每月服务费款项及额外奖励之和；</p>
        <p style="text-indent:46px">c）要求公会赔偿平台及平台关联公司的损失，损失范围包括但不限于平台及／或平台关联公司因此导致的直接损失、间接损失、预期利益的损失以及支出的律师费、调查取证费、公证费、交通费等合理性开支；</p>
        <p style="text-indent:46px">d）立即无条件解除本协议。</p>
        <p>2.除本合同另有约定外，任何一方违反其于本协议项下的陈述、承诺、保证或义务，而使另一方遭受任何诉讼、纠纷、索赔、处罚等的，违约方应负责解决，使另一方发生任何费用、额外责任或遭受直接经济损失的，应当负责赔偿。</p>
        <p>3.除本合同另有约定外，当一方（违约方）实质性违反其在本协议项下的义务，并且经非违约方请求后仍不履行，非违约方产生对本协议的解除权并有权要求违约方赔偿因此产生的损失。</p>
        <p class="title">六、知识产权</p>
        <p>1.本协议未授予其对对方商标、标识、平台Logo或域名任何权益，包括所有权或其他许可（本协议所述除外）；除为履行本协议目的，一方未经对方事先书面同意，不得擅自使用或授权他人使用。 本协议期满或终止后，本条款仍继续有效。
        </p>
        <p>2.本协议项下公会主播在光恋所表演的作品之相关权利，平台有权在光恋上使用，也有权在平台对其平台推广（推广方式及范围不限）中使用。</p>
        <p class="title">七、协议期限本协议期限【12】个月，合作期满，平台享有优先续约权。</p>
        <p>如因平台业务需要或战略调整，平台有权单方解除合同，但应提前十个工作日通知公会，并向公会支付应付的各项费用。</p>
        <p class="title">八、其他</p>
        <p>1.公会不得将本协议项下的任何权利或义务全部或部分转让、分包或委托给任何第三方，否则平台有权随时提前终止本合同并追究公会的违约责任。
            平台有权将协议项下的任何权利或义务全部或部分转让给平台关联公司，而无须另行征得公会的同意。“关联公司”就某公司而言是指通过股权、合同或其他方式控制该特定公司，受该特定公司控制或与该特定公司同为某一集团（公司）或个人所控制的其他公司。
        </p>
        <p>2.本协议未尽事宜，平台公会双方可另行签署补充协议，补充协议与本协议具有同等法律效力。</p>
        <p>3.本协议履行过程中如发生争议，双方应通过友好协商解决；如不能协商解决双方可向协议签订地人民法院提起诉讼。</p>
        <p>4.本协议仅适用中华人民共和国大陆地区有关法律、法规，并排除冲突法规的适用。</p>
        <p>5.本协议任何标题仅作为参考，不影响本合同的含义及其解释。</p>
        <h1 id="players">《玩家入会协议》</h1>
        <p>玩家加入公会前，请玩家务必充分阅读并理解本入会须知的全部内容。一旦玩家勾选“我已阅读并同意《光恋公会协议》”按钮，视为玩家对本入会须知项下全部条款的同意与接受；如果玩家对于本入会须知存在异议，请停止加入公会。
        </p>
        <p>基于法律法规规定、业务开展情况等因素的更新与变化，平台有权在必要时修订本入会须知的内容，修订后本平台会将修订后的版本在本平台公会页面进行公示。</p>
        <p class="title">一、重要须知</p>
        <p>玩家应于加入公会前先行了解并知悉该公会的各项合作条件，包括但不限于玩家待遇、运营服务内容、玩家管理制度等，平台建议玩家与公会签订书面合作协议，以维护及保障玩家的自身权益。</p>
        <p>玩家点击“确认加入”按钮，视为玩家已同意加入该公会，并在合作期限内作为该公会管理的玩家，公会可依据公会之运营规划、经营策略等向玩家提供运营服务及对玩家进行管理，包括但不限于对玩家进行培训、考核，安排、运作、监督玩家在本平台上的行为，核查、监督、纠正玩家的履约/违规情况，设定/调整玩家分成比例，终止与玩家之间的合作关系（包括淘汰玩家/发起转会）等。
        </p>
        <p>为确保公会得以向玩家履行管理义务及提供运营服务，玩家同意授予公会通过直播开放平台查看、使用玩家使用本平台及直播开放平台服务所产生的信息、内容及数据，包括玩家帐号信息、玩家发布内容（如直播内容、视频内容、封面、标题、评论等）、玩家直播行为及互动数据（如开播时间、直播时长、互动人数、粉丝数、打赏人次等）、玩家视频发布及互动数据（如视频发布数量、播放量、点赞量、评论量等）、玩家违规数据（如违规记录、处罚方式、处罚时间、违规原因等）、玩家使用本平台推广服务的相关数据，以及其他为保障公会履行玩家管理职责及提供玩家运营服务、结算合作收入所需要的信息、内容及数据。
        </p>
        <p>玩家在加入公会后，除非与公会另行达成一致，或与公会合作期限届满后自动退会，或按照相关平台的退会政策退会，否则玩家不得擅自脱离其合作公会或转至其他公会（无论通过线上、线下任何方式），亦不得以自己名义另行或借用他人名义开设其他直播帐号（“小号”），否则本平台有权对玩家采取处罚措施，包括但不限于封禁玩家帐号、封禁直播权限、限制帐号部分功能、断流、警告、扣除帐号内全部或部分收益金额、重置帐号实名认证信息、设置小号转入原公会管理等。为免疑义，玩家在加入公会后，若公会发生分立、合并、业务转移等情形导致公会主体变更的，将由变更后的公会主体承继其应对玩家履行的义务及享有的权利，玩家的合作公会名称亦将相应变更；玩家认可，本平台依据公会业务安排执行上述变更，公会与玩家之间因此产生任何纠纷和问题的，应由公会和玩家自行解决，本平台不承担责任。
            玩家有义务严格遵守本平台之各项规则及要求（包括本平台已发布及可能未来不时发布的对用户的各项管理规则及要求等，包括但不限于本协议及用户服务协议、隐私政策、社区自律公约、玩家入会协议、公会协议、充值协议、费用结算协议、玩家退会须知、付费退会须知、直播平台商品分享社区规范等，以下合称“平台规则”）。如玩家违规，平台有权视玩家违规情节严重程度作出独立判断，并依据平台规则对玩家及公会实施相应的处罚。针对已开放申诉渠道的违规处罚类型，（具体以违规处罚详情页所展示的申诉入口为准），如玩家及/或公会不认可该等处罚结果，可于违规处罚后60日内向平台提起申诉；玩家/公会逾期提起申诉的，平台不再受理。单次违规仅可申诉一次，玩家或公会其中任一方提起申诉后，另一方无法再重复提起，玩家与公会应就申诉相关事宜（包括但不限于提起主体、申诉理由及相关说明、证据材料）进行充分事前沟通，并在达成一致后再提起申诉。申诉结果将同步至玩家与公会。
        </p>
        <p>玩家与公会之间发生任何纠纷的，应积极、友好协商，并尽可能沟通解决；玩家与公会经协商仍无法达成一致的，可邀请本平台居中调解；但任何情况下，平台均不对玩家与公会之间的纠纷承担任何义务或责任。</p>
        <p class="title">二、公会合作期限</p>
        <p>玩家加入公会后，在与公会的合作期限内作为该公会旗下玩家受该公会的管理，同时享受该公会提供的运营服务。玩家与其合作公会之间的合作期限为：【12】个月</p>
        <p>为便于玩家得以连续享受公会提供的运营服务，平台提供合作期限到期自动续期的服务，以避免玩家因疏忽等原因导致未能及时延长合作期限从而遭受损失，玩家不可撤销地授权平台在合作期限到期时，将玩家与公会的合作期限继续延长一年（下一合作期限到期时再延长一年，延长次数不限）。
        </p>
        <p>玩家若不需要平台提供前述合作期限到期自动续期服务的，可办理退会。退会后届时玩家不再受该公会管理，亦不能再享受该公会提供的服务，但可继续通过平台开展直播等活动。</p>
        <p class="title">三、玩家退会</p>
        <p>玩家拟退出公会的，可采取如下解决方案：</p>
        <p>（1）经同公会协商一致，玩家可提起退会申请，公会审批通过后，玩家即可退出公会；</p>
        <p>（2）入会【90】日内的玩家（但公会申请为“优质玩家”身份并经平台审核通过的玩家除外）提起退会申请后，无需公会审批通过即可直接退出公会；</p>
        <p>（3）若玩家无法就退出公会事宜与其合作公会达成一致，玩家可邀请平台协助处理，平台将在评估玩家与公会两方诉求以及各自提供的相应证据之后依据平台规则提供解决方案，玩家和公会应尊重、认可并执行平台提供的解决方案。但是，若玩家尚处于封禁期间，则不得提起任一类型退会申请；
        </p>
        <p>如遇下述任一情形时，视为玩家退出公会：</p>
        <p>（1）该公会依据其运营规划、经营策略决定淘汰玩家的；</p>
        <p>（2）公会依据平台转会流程发起转会，且经转入公会及玩家同意的；</p>
        <p>（3）平台与该公会之间的合作关系终止的，包括但不限于平台与公会的合作期限届满、两方协商一致终止合作、公会违规被清退、公会违约导致合作提前终止等。</p>
        <p>为免疑义，三方约玩家不适用上述第四条第1款至第2款项下的关于玩家退会的约定；同时，两方约玩家、三方约玩家退出其合作公会的，不影响其签约玩家身份，玩家仍应继续依据两方合约、三方合约项下的约定履行其义务，直至该等两方合约、三方合约终止。
        </p>
        <p class="title">四、玩家互动PK</p>
        <p>本平台与直播开放平台可能会为公会和玩家提供便利于玩家参与本平台互动服务（如玩家PK撮合）的各项功能。</p>
        <p>为帮助玩家寻找符合条件的其他玩家进行PK互动撮合，进一步提升直播互动效果及玩家直播收益，玩家同意授予公会通过直播开放平台发布/接受玩家PK邀请，并据此对外披露玩家相关信息及数据的权利，包括玩家帐号信息（如帐号、昵称、头像、性别等）、玩家互动及营收指标（如粉丝数量、近30日流水区间等）、PK相关信息（如计划PK时间等），以及其他为实现上述目的所需要的信息及数据。
        </p>
        <p class="title">五、其他</p>
        <p>本入会须知为《光恋公会协议》的有效组成部分，本入会须知中所使用的术语，除非另有说明，否则其定义均与《光恋公会协议》保持一致。</p>









    </div>
</template>
<script>


export default {
    name: 'guildAgreement',
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-size: 40px;
    text-align: center;
    margin: 20px 0;
}

h2 {
    font-size: 30px;
    text-align: center;
    margin: 0;
}

p {
    font-size: 28px;
    text-indent: 56px;
    color: rgba(0, 0, 0, .8);
}

.title {
    font-size: 32px;
    color: #000;
    font-weight: bold;
}
</style>